import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import bg from "../assets/bg-3.png";

function createData(title, desc) {
  return { title, desc };
}

const DevelopmentRoadmap = () => {
  return (
    <div
      className="section"
      style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
      id="roadmap"
    >
      <Container className="container">
        <div className="title-container">
          <h2
            data-aos="fade-in"
            data-aos-offset="250"
            style={{ textAlign: "center" }}
          >
            ROADMAP
          </h2>
          <p style={{ textAlign: "center" }}>
            Our goal is to make sure that the Undead Pastel Club community
            remains strong and united for a long time. To do so, we will
            implement several strategies after the launch using the community
            fund.
          </p>
        </div>
        <TableContainer style={{ overflow: "hidden" }}>
          <Table aria-label="simple table">
            <TableBody className="table-body">
              <TableRow
                className="table-row"
                data-aos="zoom-out-up"
                data-aos-offset="250"
              >
                <TableCell scope="row" className="table-cell">
                  <h3>Pre-Mint</h3>
                </TableCell>
                <TableCell align="right" className="table-cell table-cell-desc">
                  <p>$250,000 in Marketing Budget</p>
                </TableCell>
              </TableRow>
              <TableRow
                className="table-row"
                data-aos="zoom-out-up"
                data-aos-offset="250"
              >
                <TableCell scope="row" className="table-cell"></TableCell>
                <TableCell align="right" className="table-cell table-cell-desc">
                  <p>ETH & NFT Giveaways on our Socials</p>
                </TableCell>
              </TableRow>
              <TableRow
                className="table-row"
                data-aos="zoom-out-up"
                data-aos-offset="250"
              >
                <TableCell scope="row" className="table-cell"></TableCell>
                <TableCell align="right" className="table-cell table-cell-desc">
                  <p>Exclusive Pre-Sale discount for Whitelisted Members</p>
                </TableCell>
              </TableRow>
              <TableRow
                className="table-row"
                data-aos="zoom-out-up"
                data-aos-offset="250"
              >
                <TableCell scope="row" className="table-cell">
                  <h3>Post Sell-Out</h3>
                </TableCell>
                <TableCell align="right" className="table-cell table-cell-desc">
                  <p>Launch of our Merch Collection</p>
                </TableCell>
              </TableRow>
              <TableRow
                className="table-row"
                data-aos="zoom-out-up"
                data-aos-offset="250"
              >
                <TableCell scope="row" className="table-cell"></TableCell>
                <TableCell align="right" className="table-cell table-cell-desc">
                  <p>Creation of a UPC Token with NFT staking features</p>
                </TableCell>
              </TableRow>
              <TableRow
                className="table-row"
                data-aos="zoom-out-up"
                data-aos-offset="250"
              >
                <TableCell scope="row" className="table-cell"></TableCell>
                <TableCell align="right" className="table-cell table-cell-desc">
                  <p>
                    Community Fund for future raffles and marketing campaigns
                  </p>
                </TableCell>
              </TableRow>
              <TableRow
                className="table-row"
                data-aos="zoom-out-up"
                data-aos-offset="250"
              >
                <TableCell scope="row" className="table-cell"></TableCell>
                <TableCell align="right" className="table-cell table-cell-desc">
                  <p>Acquisition of Metaverse Land</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default DevelopmentRoadmap;
