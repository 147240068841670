import img from "../assets/flask.gif";
import bg from "../assets/bg-3.png";
import Button from "./Button";

const Section = () => {
  return (
    <div
      style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
      id="about"
    >
      {/* <div style={{ background: `#f9f9f9` }}> */}
      <div className="section">
        <div
          className="section-container"
          data-aos="fade-up"
          data-aos-offset="250"
        >
          <img src={img} className="psychic" />
        </div>
        <div
          className="section-container"
          data-aos="fade-up"
          data-aos-offset="250"
        >
          <div className="section-text">
            <div className="subtitle">The project</div>
            <h2>Undead Pastel Club</h2>
            <p>
              The Undead Pastel Club is a derivative NFT collection of 9,999
              pastel apes with a community focus. Each NFT is algorithmically
              generated using a combination of over 140 attributes such as hats,
              eyes, skins and much more!
            </p>
            <p>
              Launched on the Ethereum (ETH) blockchain, the Undead Pastel Club
              aims at offering high-quality art at an affordable price while
              executing an extensive roadmap. With staking features and
              Metaverse land acquisition as key parts of our roadmap, the Undead
              Pastel Club team strives for longetivity.
            </p>
            <p>
              The implementation of a community fund will also allows for future
              marketing expenses to be covered, which means that the project
              will still garner attention from the public even after the
              collection is sold out.
            </p>
            <p>
              Join our community on Discord today for a chance to join the
              Whitelist!
            </p>
            <Button
              text="Join Discord"
              newWindow={1}
              location="https://discord.com/invite/WkBaBzsBq8"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
